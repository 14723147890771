@import 'scss/typography';
@import 'scss/media-queries';
@import 'scss/mixins';

.login {
  .quickLink,
  .continueWithEmail {
    @include label-md;
  }

  .grid {
    display: grid;
    gap: calc(var(--space-4));

    .customInput {
      margin-top: 0px;
    }
  }

  .submitButtons {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    align-items: center;
  }

  .passwordWrapper {
    position: relative;

    .password {
      margin-bottom: 0;
    }
  }
  .forgotPassword {
    @include text-sm;
    display: block;
    text-align: left;
    margin: var(--space-1) 0px 0px 10px;
  }
}
