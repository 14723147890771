@import 'scss/typography';

.lockout {
  --card-padding: var(--space-12) var(--space-10);

  display: grid;
  grid-row-gap: 16px;
  text-align: center;
  padding: var(--card-padding);

  .lockoutText {
    @include label-md;
    margin-bottom: var(--space-2);
  }

  .title {
    @include header-4-bold;
    color: var(--text-color-grey-1);
  }

  @include until-phone {
    --card-padding: var(--space-12) var(--space-6);
  }
}
