@import 'scss/typography';

.status {
  --primary-color: var(--cancel);
  --secondary-color: var(--cancel-light);

  display: flex;
  overflow: hidden;

  &.warning {
    --primary-color: var(--warning);
    --secondary-color: var(--warning-light);
  }

  &.error {
    --primary-color: var(--error);
    --secondary-color: var(--error-light);
  }

  &.info {
    --primary-color: var(--info);
    --secondary-color: var(--info-light);
  }

  &.success {
    --primary-color: var(--success);
    --secondary-color: var(--success-light);
  }

  &Buffer {
    width: 4px;
    background-color: var(--primary-color);
    border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
  }

  &Container {
    padding: var(--space-4) var(--space-4) var(--space-4) var(--space-2);
    background-color: var(--secondary-color);
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: var(--space-2);
    width: 100%;
    border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  }

  &Icon {
    color: var(--primary-color) !important;
  }

  &Header {
    @include label-md-bold;
    line-height: 24px;
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--space-1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.isCollapsed {
      margin-bottom: 0px;
    }

    &.expandButton {
      cursor: pointer;
    }

    @include until-phone {
      @include label-sm-bold;
    }
  }

  &Message {
    word-break: break-word;
    @include label-md;
    line-height: 24px;
    color: var(--text-color);
    text-align: left;

    @include until-phone {
      @include label-sm;
    }
  }

  &Cta {
    display: block;
    margin-top: var(--space-4);
    color: var(--primary-variant-1) !important;
    background-color: var(--primary-color);
    padding: var(--space-1) var(--space-4);
  }
}

.twoColumns {
  display: flex;
  flex-direction: row;
  gap: var(--space-4);

  .msgContainer {
    flex-grow: 1;
  }

  .ctaContainer {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
