@import 'scss/mixins';
@import 'scss/typography';

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  15%,
  45%,
  75% {
    transform: translateX(-4px);
  }
  30%,
  60%,
  90% {
    transform: translateX(4px);
  }
}

.title {
  @include header-4-bold;
  color: var(--text-color-grey-1);
  margin-bottom: var(--space-4);
}

.description {
  margin-bottom: var(--space-6);
  @include label-md;
  max-width: 400px;
  text-align: center;
}

.question {
  margin-top: var(--space-6);
  @include label-sm;
}

.verify {
  margin-top: var(--space-6);
}

.phoneInput {
  text-align: left;
}

.continueWithoutPromo {
  display: block;
  text-align: center;
  margin: var(--space-6) auto 0;
}

.ellipse {
  font-size: var(--text-xs);
  color: var(--text-color-grey-3);
  margin: 0 var(--space-2);
}

.field {
  display: grid;
  justify-content: center;

  .inputs {
    display: flex;
    gap: var(--space-2);

    input {
      display: flex;
      @include size(48px);
      @include header-4-bold;
      padding: var(--space-4);
      justify-content: center;
      align-items: center;
      border: 1px solid var(--neutral-3);
      border-radius: var(--border-radius-md);
      text-align: center;

      @include until-phone {
        width: 45px;
        font-size: var(--header-3) !important;
        padding: var(--space-3);
      }

      &:focus,
      &:active {
        border-color: var(--primary-variant-2);
      }
    }
  }

  &.error {
    animation: shake 0.2s ease-in-out 0s 1;

    .inputs input {
      border-color: var(--error);
    }

    .errorText {
      margin-top: var(--space-1);
      color: var(--error);
      text-align: left;
    }
  }
}
