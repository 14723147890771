@import 'scss/typography';
@import 'scss/mixins';
@import 'scss/media-queries';

.card {
  --card-width: 480px;
  --card-shadow: var(--shadow-bottom-4);
  --rounded: var(--border-radius-24);

  background: var(--white);
  max-width: var(--card-width);
  box-shadow: var(--card-shadow);
  border-radius: var(--rounded);
  margin: var(--space-10) auto;
  overflow: hidden;

  @include until-phone {
    --side-margin: 20px;

    max-width: auto;
    margin: var(--space-10) var(--side-margin);
    width: calc(100vw - var(--side-margin) * 2);
  }

  .content {
    position: relative;

    &.loading {
      &::before {
        content: '';
        background: var(--white);
        z-index: 10;
        opacity: 0.7;
        @include absFullSize;
        @include pulsing;
      }
    }
  }
}
