.container {
  display: grid;
  row-gap: var(--space-4);

  .buttons {
    display: flex;
    gap: var(--space-4);
    width: 100%;
  }
}
