// common styles used by auth screens
@import 'scss/typography';
@import 'scss/media-queries';
@import 'scss/mixins';

.marginLess {
  margin: 0;
}

// used by login and signup screens
.screen {
  display: grid;
  align-items: stretch;
  grid-template-rows: min-content 1fr;
  min-height: 640px;
  position: relative;

  @include until-phone {
    height: 100svh;
  }

  .header {
    align-self: start;
    background: var(--neutral-7);
    padding: var(--space-14) var(--space-6) var(--space-4);

    display: flex;
    gap: 24px;

    @include until-phone {
      padding: var(--space-20) var(--space-6) var(--space-4);
    }

    svg {
      align-self: flex-end;
      max-width: 58px;
      width: auto;
    }

    .title {
      @include header-4;
      color: var(--text-color);
      margin-bottom: var(--space-2);

      @include until-phone {
        @include header-3;
      }
    }

    .subtitle {
      @include label-md;
      color: var(--text-color);
      min-height: 48px;
    }
  }

  .content {
    text-align: center;
    padding: var(--space-6);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bottomContent {
      width: 100%;
      margin-top: var(--space-4);

      .authSwitcher {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: var(--space-1);
        @include label-sm-bold;
      }
    }
  }
}

.cta {
  @include label-md;
  height: 48px;
}

.authButton {
  gap: var(--space-4);
  padding: var(--space-4) var(--space-6);
  width: 100%;

  border-radius: var(--border-radius-md);
  @include label-md;
  color: var(--text-color);
  outline: 1px solid var(--neutral-4);

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  transition: all 0.2s ease-out;

  &:focus:not(&:hover, &:active) {
    outline-offset: var(--space-1-half);
    outline: var(--space-1-half) solid var(--neutral-1);
  }

  &:hover {
    outline-offset: 0;
    outline: 1px solid var(--neutral-1);
  }

  &:active {
    outline: var(--space-1-half) solid var(--neutral-4);
  }

  &[disabled] {
    background: var(--neutral-5);
    pointer-events: none;
  }

  img {
    @include size(24px);
  }
}

.divider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-6) 0;

  &::before,
  &::after {
    position: absolute;
  }

  &:before {
    content: '';
    height: 1px;
    background: var(--neutral-4);
    top: 50%;
    width: 100%;
  }

  &:after {
    content: attr(data-text);
    display: inline-block;
    background: var(--white);
    padding: var(--space-1) var(--space-1-half);
    @include text-sm;
    color: var(--text-color-grey-2);
  }
}

.socialButtonsLayout {
  flex-direction: column;

  > button,
  form > button {
    justify-content: space-between;
  }
}

.fullWidth {
  width: 100%;
}

.backBtn {
  position: absolute;
  top: 20px;
  left: 20px;
  opacity: 0.6;
  z-index: 1;
  padding: 0px;

  &:hover {
    opacity: 1;
  }
}

.emailButton {
  margin-top: var(--space-4);
  justify-content: space-between;
}

.continueWithEmail {
  text-align: left;
  margin-top: var(--space-2);
  margin-bottom: var(--space-4);
  @inlcude header-6-bold;
}

.kfLogo {
  max-width: 60px;
  width: 100%;
}
