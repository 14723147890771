@import 'scss/typography';

.privacyPolicy {
  max-width: 320px;

  margin: var(--space-4) auto 0;
  @include text-sm;
  color: var(--text-color-grey-2);

  a {
    font-weight: var(--font-weight-regular) !important;
  }
}
