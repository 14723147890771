@import 'scss/typography';

.existingAccount {
  --row-gap: var(--space-4);
  --card-padding: var(--space-12) var(--space-10);

  display: grid;
  grid-row-gap: var(--row-gap);
  text-align: center;
  @include label-md;
  position: relative;
  padding: var(--card-padding);
  justify-items: center;

  .title {
    @include header-4-bold;
    color: var(--text-color-grey-1);
  }

  .email {
    color: var(--text-color-grey-2);
    margin-bottom: var(--space-2);
  }

  > button {
    justify-content: space-between;
  }

  @include until-phone {
    --card-padding: var(--space-12) var(--space-6);
  }
}
