@import 'scss/typography';
@import 'scss/media-queries';
@import 'scss/mixins';

form {
  :not(.grid) .error {
    margin-bottom: var(--space-4);
  }

  .grid {
    display: grid;
    gap: var(--space-2);
    margin-top: var(--space-4);

    .error {
      grid-column: 1/-1;
      direction: ltr;
    }

    &.twoColumns {
      gap: var(--space-4);
      grid-template-columns: 100%;
    }

    @include from-phone {
      &.twoColumns {
        grid-template-columns: calc(50% - var(--space-2)) calc(50% - var(--space-2));
      }

      &.reverse {
        direction: rtl;
      }
    }
  }

  .descriptionText {
    padding-top: var(--space-2);
  }

  .marketingEmail {
    text-align: left;
    margin-top: var(--space-2);
    margin-bottom: var(--space-4);
    color: var(--text-color-grey-2);

    span {
      @include text-sm;
    }
  }
}

.referralSignup {
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
  justify-content: space-between;
  align-items: end;

  @include until-phone {
    padding-top: var(--space-12) !important;
    text-align: left;
  }

  h2 {
    margin-bottom: 0;
    text-align: left;
  }
}

.termsAndConditions {
  margin-top: var(--space-20) !important;

  @include until-phone {
    margin-top: var(--space-2) !important;
  }
}
